import { useCallback, useEffect, useRef, useState } from "react"
import { Section } from "../../../../packages/editing/Section"
import { Localized } from "../../../../packages/localization/Localized"
import { Markdown, NonNegativeNumber, Uuid } from "../../../../reactor"
import { FaqItem } from "../ui/components/blocks/Faq"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Heading } from "../ui/components/typography/Heading"
import { css } from "@emotion/react"
import { responsiveCss, responsiveSectionSpacing, scaleValue } from "../ui/helpers/css"
import { Flex } from "../ui/components/base/Flex"
import { Button } from "../ui/components/buttons/Button"
import { motion } from "framer-motion"

/**
 * @icon ui-help-square
 */
function FrequentlyAskedQuestions(section: {
    heading: Localized<string>

    /**
     * @expand
     */
    questions: Question[]

    /**
     * The maximum number of questions initially displayed before clicking "Show all" button.
     * @default 3
     */
    maxInitialQuestions: NonNegativeNumber

    /**
     * Text for button to show all questions.
     */
    showAllButtonText: Localized<string>
}) {
    const localize = useLocalize()
    const [openQuestions, setOpenQuestions] = useState<Uuid<"Question">[]>([])
    const [showAll, setShowAll] = useState(false)

    const handleClick = useCallback(
        (id: Uuid<"Question">) => () => {
            const alreadyOpen = openQuestions.includes(id)
            setOpenQuestions(
                alreadyOpen ? openQuestions.filter((q) => q !== id) : [...openQuestions, id]
            )
        },
        [openQuestions]
    )

    const containerRef = useRef<HTMLDivElement>(null)
    const [restQuestionsHeight, setRestQuestionsHeight] = useState<number | string>(0)

    const updateHeight = useCallback(() => {
        setRestQuestionsHeight(containerRef.current?.clientHeight || 0)
    }, [])

    useEffect(() => {
        if (showAll && containerRef.current?.clientHeight) {
            updateHeight()
        }
    }, [showAll, updateHeight])

    return (
        <div css={css(responsiveSectionSpacing())}>
            <Flex
                alignItems="center"
                justifyContent="space-between"
                css={css({ marginBottom: 16 }, responsiveCss("min", "md", { marginBottom: 24 }))}>
                <Heading level={2}>{localize(section.heading)}</Heading>
                <Flex
                    shrink={0}
                    margin={{ left: 16 }}
                    css={responsiveCss("max", "xs", {
                        display: "none",
                    })}>
                    <Button
                        size="sm"
                        variant="secondary"
                        onClick={() => setShowAll(true)}
                        disabled={showAll}>
                        {localize(section.showAllButtonText)}
                    </Button>
                </Flex>
            </Flex>
            {section.questions.slice(0, section.maxInitialQuestions.valueOf()).map((q) => (
                <div
                    key={q.id.valueOf()}
                    onClick={handleClick(q.id)}
                    css={css(
                        { marginBottom: 8, "&:last-of-type": { marginBottom: 0 } },
                        responsiveCss("min", "md", { marginBottom: scaleValue(16) })
                    )}>
                    <FaqItem
                        question={localize(q.question)}
                        answer={localize(q.answer)}
                        isOpen={openQuestions.includes(q.id)}
                    />
                </div>
            ))}
            {showAll && (
                <motion.div
                    initial={{ height: 0 }}
                    animate={{
                        height: restQuestionsHeight,
                    }}
                    onAnimationComplete={() => {
                        // After animation is done, set height to auto, to automatically get the
                        // correct height when opening questions or window is resized.
                        setRestQuestionsHeight("auto")
                    }}
                    css={css(
                        {
                            marginTop: 8,
                        },
                        responsiveCss("min", "md", {
                            marginTop: scaleValue(16),
                        })
                    )}>
                    <div ref={containerRef}>
                        {section.questions
                            .slice(section.maxInitialQuestions.valueOf(), section.questions.length)
                            .map((q, index) => (
                                <motion.div
                                    initial={{
                                        opacity: 0,
                                        transform: `translateY(-${index + 1 * 80}px)`,
                                    }}
                                    animate={
                                        showAll ? { opacity: 1, transform: "translateY(0px)" } : {}
                                    }
                                    key={q.id.valueOf()}
                                    onClick={handleClick(q.id)}
                                    css={css(
                                        { marginBottom: 8, "&:last-of-type": { marginBottom: 0 } },
                                        responsiveCss("min", "md", { marginBottom: scaleValue(16) })
                                    )}>
                                    <FaqItem
                                        question={localize(q.question)}
                                        answer={localize(q.answer)}
                                        isOpen={openQuestions.includes(q.id)}
                                    />
                                </motion.div>
                            ))}
                    </div>
                </motion.div>
            )}

            <Button
                size="sm"
                variant="dark"
                onClick={() => setShowAll(true)}
                disabled={showAll}
                fullwidth
                margin={{ top: 8 }}
                css={responsiveCss("min", "sm", { display: "none" })}>
                {localize(section.showAllButtonText)}
            </Button>
        </div>
    )
}

type Question = {
    readonly id: Uuid<"Question">

    /**
     * @title
     * @icon ui-message-question-circle
     */
    question: Localized<string>

    answer: Localized<Markdown>
}

Section(FrequentlyAskedQuestions)
