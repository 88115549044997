import { css } from "@emotion/react"
import { Section } from "../../../../packages/editing/Section"
import { Uuid, Image, Url } from "../../../../reactor"
import { PressLogosCarousel } from "../ui/components/carousel/PressLogosCarousel"
import { responsiveSectionSpacing } from "../ui/helpers/css"

function PressLogos(section: {
    logos: {
        readonly id: Uuid<"PressLogo">
        image: Image
        url?: Url
    }[]
}) {
    return (
        <div
            css={css(
                {
                    display: "flex",
                    flexDirection: "column",
                },
                responsiveSectionSpacing()
            )}>
            <PressLogosCarousel logos={section.logos} />
        </div>
    )
}
Section(PressLogos)
