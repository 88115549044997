import { css } from "@emotion/react"
import { Component } from "../../../../../packages/editing/Component"
import { responsiveCss } from "../helpers/css"
import { Flex } from "./base/Flex"
import { Icon } from "./visual/Icon"
import { colors } from "../constants/colors"
import { Text } from "../components/typography/Text"

export function UniqueSellingPoints(props: { items: string[] }) {
    return (
        <Flex borderRadius="lg" justifyContent="center" css={wrapperCss}>
            {props.items.map((item) => (
                <Flex key={item} margin={{ x: 12 }} css={uspCss}>
                    <div>
                        <Icon icon="check" color="forest" />
                    </div>
                    <div css={textWrapperCss}>
                        <Text variant="body" size={["fixed", "md", "md"]}>
                            {item}
                        </Text>
                    </div>
                </Flex>
            ))}
        </Flex>
    )
}

const wrapperCss = css(
    {
        flexDirection: "column",
    },
    responsiveCss("min", "xl", {
        backgroundColor: colors.gray100,
        flexDirection: "row",
        padding: 18,
    })
)

const uspCss = responsiveCss("max", "lg", {
    borderTop: `1px solid ${colors.gray200}`,
    margin: 0,
    paddingTop: 16,
    paddingBottom: 16,
    "&:first-of-type": {
        borderTop: "none",
        paddingTop: 0,
    },
    "&:last-of-type": {
        paddingBottom: 0,
    },
})

const textWrapperCss = css(
    { marginLeft: 8 },
    responsiveCss("min", "xl", { marginLeft: 10, marginTop: 0 })
)

Component(UniqueSellingPoints, {
    name: "UniqueSellingPoints",
    gallery: {
        items: [
            {
                variants: [
                    {
                        props: {
                            items: [
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                "Donec eget fermentum lorem. Phasellus vulputate tortor a augue commodo efficitur.",
                                "Vestibulum efficitur tortor ante, quis varius tortor blandit in.",
                            ],
                        },
                    },
                ],
            },
        ],
    },
})
