import { Route } from "react-router-dom"
import { css } from "@emotion/react"

// Make generic types work with forwardRef
declare module "react" {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactNode | null
    ): (props: P & React.RefAttributes<T>) => React.ReactNode | null
}

import "./ui/components/buttons/Button"
import "./ui/components/buttons/Dropdown"
import "./ui/components/carousel/HighlightCarousel"
import "./ui/components/typography/Heading"
import "./ui/components/cards/WithOverlay"
import "./ui/components/cards/SeeAllCard"
import "./ui/components/cards/DeviceCheckoutCard"
import "./ui/components/controllers/Checkbox"
import "./ui/components/controllers/TextInput"
import "./ui/components/controllers/BoxSelection"
import "./ui/components/controllers/LocaleSelect"
import "./ui/components/base/CarouselBase"
import "./ui/components/controllers/Radio"
import "./ui/components/blocks/Faq"
import "./ui/components/modal/Modal"
import "./ui/components/modal/NavigationModal"
import "./ui/components/modal/WaitingListSignupModal"
import "./ui/components/carousel/PressLogosCarousel"
import "./ui/components/carousel/ReviewsCarousel"
import "./ui/components/carousel/ContinuousSlideCarousel"
import "./ui/components/carousel/CardsCarousel"

import "./sections/CardsWithMoreInfo"
import "./sections/Carousel"
import "./sections/Footer"
import "./sections/FrequentlyAskedQuestions"
import "./sections/FullNotification"
import "./sections/Header"
import "./sections/PressLogos"
import "./sections/PromotedProducts"
import "./sections/PromotedReviews"
import "./sections/UniqueSellingPoints"
import "./sections/WaitingListSignupModal"
import "./sections/WaitingListTrigger"

import { WebSite } from "../../../packages/web/components/WebSite"
import { globalStyles, responsiveCss } from "./ui/helpers/css"
import { useAnalyticsLogger } from "../../../packages/analytics/useAnalyticsLogger"

function sectionItemCss() {
    return css(
        {
            maxWidth: 1296,
            margin: "0 auto",
            padding: "0 16px",
            "&:last-of-type": { paddingBottom: 16 },
            // Override Bootstrap line height variable.
            "--bs-body-line-height": "1",
            lineHeight: "1",
        },
        responsiveCss("min", "md", {
            padding: "0 24px",
            "&:last-of-type": { paddingBottom: 24 },
        })
    )
}

export function App() {
    useAnalyticsLogger()

    return (
        <WebSite
            gallery={true}
            studio={true}
            globalStyles={globalStyles}
            EditableSectionsProps={{ itemCss: sectionItemCss }}
        />
    )
}
