import { ComponentProps, useCallback, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { EditableSections } from "../../../packages/editing/EditableSections"
import { useDefaultLocale, useWebPage } from "../../../studio/client"
import { LocaleKey } from "../../localization/Locale"
import { CurrentLocaleContext } from "../../localization/client-side/CurrentLocaleContext"

export function WebPage(props: {
    /**
     * The locale the page should be displayed in.
     */
    locale?: LocaleKey

    /**
     * Props to forward to each EditableSection.
     */
    EditableSectionsProps?: Partial<Omit<ComponentProps<typeof EditableSections>, "sections">>
}) {
    const navigate = useNavigate()
    const params = useParams()
    const currentLocaleContext = useContext(CurrentLocaleContext)
    const defaultLocale = useDefaultLocale().data?.defaultLocale
    const { data } = useWebPage(props.locale ?? currentLocaleContext.locale, params.slug)

    const setLocale = useCallback(
        (l: LocaleKey) => {
            if (currentLocaleContext.setLocale) {
                currentLocaleContext.setLocale(l)
            }
            if (!data) return
            // Redirect to new locale specific path.
            const newPath =
                l === defaultLocale
                    ? `/${data.page.slug?.[defaultLocale.toString()] ?? ""}`
                    : `/${l}/${data.page.slug?.[l.toString()] ?? ""}`
            navigate(newPath)
        },
        [currentLocaleContext, data, defaultLocale, navigate]
    )

    if (!data) return <></>
    return (
        <CurrentLocaleContext.Provider
            value={{
                get locale() {
                    // In context of a page, always return the locale passed to the WebPage, which
                    // should be determined by the URL.
                    return props.locale ?? currentLocaleContext.locale
                },
                setLocale,
            }}>
            <EditableSections
                sections={data.page.sections}
                {...(props.EditableSectionsProps || {})}
            />
        </CurrentLocaleContext.Provider>
    )
}
