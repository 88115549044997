import { MouseEventHandler, ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { MotionConfig, motion } from "framer-motion"
import { css } from "@emotion/react"
import { Component } from "../../../../../../packages/editing/Component"
import { Box } from "../base/Box"
import { Flex } from "../base/Flex"
import { Body } from "../typography/Body"
import { Icon } from "../visual/Icon"
import { colors } from "../../constants/colors"
import { responsiveCss } from "../../helpers/css"
import { getTransition, springAnimations } from "../../constants/animation"
import { Markdown } from "../../../../../../reactor"
import { MarkdownView } from "../../../../../../packages/markdown-edit/MarkdownView"
import { Text } from "../typography/Text"

export function FaqItem(props: {
    question: string
    answer: Markdown
    isOpen: boolean
    /**
     * @reflection any
     */
    onClick?: MouseEventHandler
}) {
    return (
        <MotionConfig transition={springAnimations["200"]}>
            <Box
                onClick={props.onClick}
                borderRadius="md"
                motion={{
                    initial: {
                        backgroundColor: props.isOpen ? colors.gray100 : colors.grayWhite,
                        borderColor: props.isOpen ? colors.gray100 : colors.gray200,
                    },
                    animate: {
                        backgroundColor: props.isOpen ? colors.gray100 : colors.grayWhite,
                        borderColor: props.isOpen ? colors.gray100 : colors.gray200,
                    },
                }}
                css={css(
                    {
                        borderStyle: "solid",
                        borderWidth: 1,
                        padding: 24,
                        cursor: "pointer",
                        overflow: "clip",
                        transition: getTransition(["border-color"], 200),
                        "&:hover": {
                            borderColor: !props.isOpen
                                ? `${colors.gray300} !important`
                                : "transparent",
                        },
                    },
                    responsiveCss("min", "md", {
                        borderWidth: 2,
                        paddingTop: "calc(40px * var(--scale-factor))",
                        paddingBottom: "calc(40px * var(--scale-factor))",
                    })
                )}>
                <Flex justifyContent="space-between">
                    <Body size="lg">{props.question}</Body>
                    <Icon icon={props.isOpen ? "arrowUp" : "arrowDown"} margin={{ left: 20 }} />
                </Flex>
                <motion.div
                    initial={{ marginTop: props.isOpen ? "calc(20px * var(--scale-factor))" : 0 }}
                    animate={{ marginTop: props.isOpen ? "calc(20px * var(--scale-factor))" : 0 }}>
                    <Collapsible collapsed={!props.isOpen}>
                        <Text variant="body" size="md">
                            <MarkdownView value={props.answer} />
                        </Text>
                    </Collapsible>
                </motion.div>
            </Box>
        </MotionConfig>
    )
}

Component(FaqItem, {
    name: "FaqItem",
    gallery: {
        path: "Blocks/FAQ",
        items: [
            {
                variants: [
                    {
                        label: "Closed state",
                        props: (state = { isOpen: false }, setState) => ({
                            question: "How does this work?",
                            answer: Markdown(
                                "Proin consectetur nunc non augue rhoncus, eget scelerisque massa tempor. Integer tincidunt, leo eget sagittis eleifend, turpis magna vehicula massa, sed pharetra arcu tellus eget tortor."
                            ),
                            isOpen: state.isOpen,
                            onClick: () => {
                                setState({ isOpen: !state.isOpen })
                            },
                        }),
                    },
                ],
            },
            {
                variants: [
                    {
                        label: "Open state",
                        props: (state = { isOpen: true }, setState) => ({
                            question: "More questions?",
                            answer: Markdown(
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta, magna eu efficitur pretium, nisi tellus gravida mauris, vel venenatis sem magna nec massa. Vivamus laoreet, dui vitae elementum viverra, mauris nisl aliquam erat, in feugiat purus lorem ac odio. Fusce venenatis vel dui at vestibulum. Praesent ut lectus a felis ullamcorper auctor. Etiam non malesuada diam. In hac habitasse platea dictumst. Praesent in dolor rhoncus, euismod sapien nec, rhoncus justo. Duis vitae arcu ligula."
                            ),
                            isOpen: state.isOpen,
                            onClick: () => {
                                setState({ isOpen: !state.isOpen })
                            },
                        }),
                    },
                ],
            },
        ],
    },
})

export function Collapsible({ collapsed, children }: { collapsed: boolean; children: ReactNode }) {
    const containerRef = useRef<HTMLDivElement>(null)
    const [height, setHeight] = useState(0)

    const updateHeight = useCallback(() => {
        setHeight(containerRef.current?.clientHeight || 0)
    }, [setHeight])

    useEffect(() => {
        if (!collapsed && containerRef.current?.clientHeight) {
            updateHeight()
        }
    }, [collapsed, updateHeight])

    useEffect(() => {
        const resizeObserver = new ResizeObserver(updateHeight)
        resizeObserver.observe(document.body)
        return () => {
            resizeObserver.disconnect()
        }
    }, [updateHeight])

    return (
        <motion.div
            initial={{ height: collapsed ? height : "auto" }}
            animate={{
                height: collapsed ? 0 : `${height}px`,
                opacity: collapsed ? 0 : 1,
            }}
            css={css({
                overflow: collapsed ? "hidden" : "visible",
            })}>
            <div ref={containerRef}>{children}</div>
        </motion.div>
    )
}
