import { ChangeEvent, ReactNode, useCallback, useMemo, useRef } from "react"
import { css } from "@emotion/react"
import { Component } from "../../../../../../packages/editing/Component"
import { Box } from "../base/Box"
import { Icon } from "../visual/Icon"
import { responsiveHeadingCss, Text } from "../typography/Text"
import { responsiveCss, scaleValue } from "../../helpers/css"
import { Flex } from "../base/Flex"
import { xlScaleFactor } from "../../constants/sizes"
import { responsiveBodyCss } from "../typography/Body"

export function Select<V extends string>(props: {
    value: V
    options: { value: V; text: string }[]
    onChange?: (e: ChangeEvent<HTMLSelectElement>, val: V) => void
    renderValue?: (val: V) => ReactNode
    label?: string
}) {
    const selectRef = useRef<HTMLSelectElement>(null)

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            props.onChange?.(e, e.target.value as V)
        },
        [props]
    )

    return (
        <div>
            {props.label ? (
                <Text variant="body" size="md" style={{ marginBottom: 16 * xlScaleFactor }}>
                    {props.label}
                </Text>
            ) : null}
            <Flex
                borderColor="gray500"
                borderWidth={2}
                borderRadius="sm"
                css={selectCss}
                alignItems="center"
                justifyContent="space-between">
                <div css={valueTextCss}>
                    {props.renderValue?.(props.value) ??
                        props.options.find((opt) => opt.value === props.value)?.text}
                </div>
                <select
                    css={dropdownCss}
                    ref={selectRef}
                    value={props.value}
                    onChange={handleChange}>
                    {props.options.map((o) => (
                        <option key={o.value} value={o.value}>
                            {o.text}
                        </option>
                    ))}
                </select>
                <Icon icon="chevronDown" />
            </Flex>
        </div>
    )
}

const valueTextCss = css(
    responsiveHeadingCss("5"),
    responsiveCss("min", "md", responsiveBodyCss("md") ?? {})
)

const selectCss = css`
    position: relative;
    height: ${48 * xlScaleFactor}px;
    padding: ${12 * xlScaleFactor}px ${20 * xlScaleFactor}px;
`

const dropdownCss = css({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    minWidth: 0,
    appearance: "none",
    opacity: 0,
    cursor: "pointer",
})

Component(Select, {
    name: "Select",
    gallery: {
        path: "Inputs/Select",
        items: [
            {
                variants: [
                    {
                        props: {
                            value: "foo",
                            options: [
                                { value: "foo", text: "Foo" },
                                { value: "bar", text: "Bar" },
                            ],
                        },
                    },
                ],
            },
        ],
    },
})
