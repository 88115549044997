import { ReactNode } from "react"
import { css, keyframes } from "@emotion/react"
import { Component } from "../../../../../../packages/editing/Component"
import { File, FileToUrl, Image, Url, Uuid } from "../../../../../../reactor"
import { responsiveCss } from "../../helpers/css"

export function ContinuousSlideCarousel<T>(props: {
    // renderItem: (item: T) => ReactNode
    /**
     * @reflection any
     */
    items: ReactNode[]
}) {
    // const ref = useRef<HTMLDivElement>(null)
    // const [width, setWidth] = useState(0)
    //
    // useEffect(() => {
    //     setWidth(ref.current?.getBoundingClientRect().width || 0)
    // }, [])
    //
    // const animationDuration = useMemo(
    //     () => `${(width ?? 0) * 10 || props.reviews.length * 5000}ms`,
    //     [width]
    // )
    //
    // return (
    //     <div
    //         style={{
    //             position: "relative",
    //             whiteSpace: "nowrap",
    //         }}
    //         css={containerCss}>
    //         <div
    //             ref={ref}
    //             style={{
    //                 animationDuration,
    //             }}
    //             css={[responsiveGridCss, animationCss]}>
    //             {props.reviews.map(({ id, ...review }) => (
    //                 <div key={id} css={responsiveItemCss}>
    //                     <ReviewCard {...review} />
    //                 </div>
    //             ))}
    //         </div>
    //         <div
    //             aria-hidden
    //             style={{
    //                 animationDuration,
    //             }}
    //             css={[responsiveGridCss, animationCss]}>
    //             {props.reviews.map(({ id, ...review }) => (
    //                 <div key={id} css={responsiveItemCss}>
    //                     <ReviewCard {...review} />
    //                 </div>
    //             ))}
    //         </div>
    //     </div>
    // )

    return (
        <div
            style={{
                position: "relative",
                overflow: "hidden",
                whiteSpace: "nowrap",
            }}
            css={containerCss}>
            <div
                style={{ animationDuration: `${props.items.length * 5}s` }}
                css={[responsiveGridCss, animationCss]}>
                {props.items.map((item) => (
                    <div>{item}</div>
                ))}
            </div>
            <div
                style={{ animationDuration: `${props.items.length * 5}s` }}
                css={[responsiveGridCss, animationCss]}>
                {props.items.map((item) => (
                    <div>{item}</div>
                ))}
            </div>
        </div>
    )
}

const marquee = keyframes({
    from: {
        transform: "translateX(0%)",
    },
    to: {
        transform: "translateX(-100%)",
    },
})

const animationCss = css({
    animationName: marquee,
})

const responsiveGridCss = css({
    display: "inline-flex",
    alignItems: "center",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
})
const containerCss = css(
    {
        maxHeight: 64,
        "&:hover div": {
            animationPlayState: "paused",
        },
        "&:before, &:after": {
            content: '""',
            position: "absolute",
            top: 0,
            bottom: 0,
            width: 50,
            zIndex: 1,
            pointerEvents: "none",
        },
        "&:before": {
            left: 0,
            background: "linear-gradient(270deg, rgba(255, 255,255,0), rgba(255, 255,255,1))",
        },
        "&:after": {
            right: 0,
            background: "linear-gradient(90deg, rgba(255, 255,255,0), rgba(255, 255,255,1))",
        },
    },
    responsiveCss("min", "md", {
        "&:before, &:after": {
            width: 360,
            maxHeight: 80,
        },
    })
)

const responsiveItemCss = css(
    {
        display: "flex",
        justifyContent: "center",
        margin: "0 16px",
        minWidth: 200,
    },
    responsiveCss("min", "md", {
        margin: "0 50px",
        minWidth: 240,
    })
)

Component(ContinuousSlideCarousel, {
    name: "ContinuousSlideCarousel",
    gallery: {
        items: [
            {
                variants: [
                    {
                        props: { items: [...new Array(5)].map(() => <div>Hello, world!</div>) },
                    },
                ],
            },
        ],
    },
})
