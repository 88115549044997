import { Section } from "../../../../packages/editing/Section"
import { Localized } from "../../../../packages/localization/Localized"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Image, Markdown, NonNegativeInteger, Uuid } from "../../../../reactor"
import { WithOverlayCard } from "../ui/components/cards/WithOverlay"
import { CardsBlock } from "../ui/components/blocks/CardsBlock"

/**
 * Shows a set of cards side by side which the user can click to get more info about something.
 *
 * @icon ui-info-square
 */
function CardsWithMoreInfo(section: {
    /**
     * Section heading.
     */
    heading: Localized<string>

    /**
     * The cards to present.
     *
     * @expand
     */
    cards: {
        readonly id: Uuid<"CardsWithMoreInfoCard">
        /**
         * The text that is shown on the bottom of the card until it is opened.
         *
         * @title
         */
        heading: Localized<string>

        /**
         * The text with more information that is shown when the card is opened.
         */
        text: Localized<Markdown>
        /**
         * Image for the card.
         */
        image?: Image
    }[]

    /**
     * Switch on to show a card number in the top left corner of each card.
     */
    showCardNumber?: boolean

    /**
     * Short string that is prefixed before the title to generate a read more.
     *
     * @label Read more prefix for screen readers.
     * @default '{"en": "More about", "no": "Mer om"}'
     */
    readMoreAriaPrefix: Localized<string>
}) {
    const localize = useLocalize()

    return (
        <CardsBlock
            heading={localize(section.heading)}
            showSlideButtons={true}
            cards={section.cards.map((c) => ({ ...c, id: c.id.valueOf() }))}
            enterAnimation={true}
            renderCard={(c, i) => (
                <WithOverlayCard
                    title={localize(c.heading)}
                    text={localize(c.text)}
                    image={c.image}
                    number={section.showCardNumber ? NonNegativeInteger(i + 1) : undefined}
                    readMoreAriaPrefix={localize(section.readMoreAriaPrefix)}
                />
            )}
        />
    )
}
Section(CardsWithMoreInfo)
