import { SerializedStyles } from "@emotion/react"
import { SVGProps } from "react"
import { Component } from "../../../../../../packages/editing/Component"
import { colors } from "../../constants/colors"

export function TrustpilotStar({
    css,
    ...rest
}: { css: SerializedStyles } & SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="none"
            viewBox="0 0 24 24"
            {...rest}>
            <path
                fill={colors._trustpilot}
                d="m11.566 17.252 4.591-1.164L18.076 22l-6.51-4.748ZM22.132 9.61H14.05L11.566 2 9.082 9.61H1l6.54 4.717-2.483 7.61 6.54-4.717 4.026-2.893 6.51-4.717Z"
            />
        </svg>
    )
}

/**
 * Colors for different ratings. 0 is default background color for stars > rating, the rest is for
 * all filled stars for ratings 1-5.
 */
const ratingColors = ["#dcdce6", "#ff3722", "#ff8622", "#ffce00", "#73cf11", colors._trustpilot]

export function TrustpilotStars(props: { rating: number }) {
    if (props.rating < 0 || props.rating > 5) {
        console.warn("Prop `rating` should be between 1 and 5.")
    }

    return (
        <div style={{ display: "grid", gap: 2, gridAutoFlow: "column" }}>
            {[1, 2, 3, 4, 5].map((i) => (
                <div
                    key={i}
                    style={{
                        display: "flex",
                        height: 20,
                        width: 20,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor:
                            i > props.rating ? ratingColors[0] : ratingColors[props.rating],
                    }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={14}
                        height={14}
                        viewBox="0 0 14 14"
                        {...props}>
                        <path
                            fill="#fff"
                            d="m7 10.676 3.042-.814L11.312 14 7 10.676Zm7-5.349H8.646L7 0 5.354 5.327H0l4.333 3.302-1.646 5.327 4.334-3.302L9.688 8.63 14 5.327Z"
                        />
                    </svg>
                </div>
            ))}
        </div>
    )
}

Component(TrustpilotStars, {
    name: "TrustpilotStars",
    gallery: {
        items: [
            {
                variants: [
                    { props: { rating: 1 } },
                    { props: { rating: 2 } },
                    { props: { rating: 3 } },
                    { props: { rating: 4 } },
                    { props: { rating: 5 } },
                ],
            },
        ],
    },
})
