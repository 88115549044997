import { ReactNode } from "react"
import { css } from "@emotion/react"
import { Component } from "../../../../../../packages/editing/Component"
import { resetStyles, responsiveHeadingSize } from "../../helpers/css"
import { Spacing, spacingToCss } from "../base/Box"
import { type Color, colors } from "../../constants/colors"

type Level = 1 | 2 | 3 | 4

export function Heading(props: {
    level: Level

    /**
     * Specify a text color from the color palette.
     */
    color?: Color

    /**
     * Specify margin.
     */
    margin?: Spacing

    /**
     * Specify padding.
     */
    padding?: Spacing

    /**
     * Truncate overflowing text. Forces nowrap and adds ellipsis.
     */
    truncate?: boolean

    /**
     * @reflection any
     */
    children: ReactNode
}) {
    const HeadingTag = `h${props.level}` as keyof JSX.IntrinsicElements

    return (
        <HeadingTag
            css={css(
                resetStyles(HeadingTag),
                responsiveHeadingSize(`${props.level}`),
                props.truncate && {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                },
                props.color && { color: colors[props.color] },
                props.margin && spacingToCss("margin", props.margin),
                props.padding && spacingToCss("padding", props.padding)
            )}>
            {props.children}
        </HeadingTag>
    )
}

Component(Heading, {
    name: "Heading",
    gallery: {
        path: "Typhography/Heading",
        about: "Using headers to organize and arrange designs in Figma is a great way to visually group components, while providing space for notes and context.",
        items: [
            { title: "Title 1", variants: [{ props: { level: 1, children: "Hello, world!" } }] },
            { title: "Title 2", variants: [{ props: { level: 2, children: "The new wave" } }] },
            {
                title: "Title 3",
                variants: [
                    { props: { level: 3, children: "We take on the complexities of leasing" } },
                ],
            },
        ],
    },
})
