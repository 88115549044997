import { ReactNode } from "react"
import { css } from "@emotion/react"
import { responsiveCss } from "../helpers/css"
import { Flex } from "./base/Flex"
import { Icon } from "./visual/Icon"

/**
 * Notification
 */
export function Notification(props: {
    /**
     * Show the notification across the whole screen width.
     */
    full?: boolean

    /**
     *  Callback for when the notification is closed by the user.
     */
    onClose?: () => void

    /**
     * The message to show in the notification, to support both plain text and JSX.
     */
    children?: ReactNode
}) {
    return (
        <Flex
            backgroundColor="forest"
            color="grayWhite"
            alignItems="center"
            style={
                props.full
                    ? {
                          width: "100vw",
                          marginLeft: "calc(50% - 50vw)",
                      }
                    : {}
            }
            css={css(
                {
                    padding: "20px 16px",
                },
                responsiveCss("min", "md", {
                    padding: "12px 24px",
                })
            )}>
            <Flex
                grow={1}
                justifyContent="center"
                style={{
                    textAlign: "left",
                }}>
                {props.children}
            </Flex>
            {props.onClose && (
                <Flex justifyContent="center">
                    <button style={{ marginLeft: 40 }} onClick={props.onClose}>
                        <Icon icon="close" color="gray100" />
                    </button>
                </Flex>
            )}
        </Flex>
    )
}
