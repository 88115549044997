import { MouseEvent, useCallback, useEffect, useRef, useState } from "react"
import { Section } from "../../../../packages/editing/Section"
import { Localized } from "../../../../packages/localization/Localized"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Email, Markdown, NonNegativeInteger } from "../../../../reactor"
import { WaitingListSignupModal as WaitingListSignupModalComponent } from "../ui/components/modal/WaitingListSignupModal"
import { postWaitingListSignup } from "../client"

const waitingListSignupModalId = "waiting-list-signup-modal"

/**
 * @icon ui-at-sign
 */
function WaitingListSignupModal(section: {
    /**
     * @title
     */
    title: Localized<string>

    text?: Localized<Markdown>

    /**
     * The label for the email address input field.
     *
     * @default '{"no": "E-postadresse", "en": "Email"}'
     */
    emailInputLabel: Localized<string>

    /**
     * The label for the phone model select.
     */
    phoneModelLabel: Localized<string>

    /**
     * The phone models the users can select from.
     *
     * @expand
     */
    phoneModelOptions: Localized<string>[]

    /**
     * The label for the rental period select.
     */
    rentalPeriodLabel: Localized<string>

    /**
     * The longest rental period the user can select (in years).
     *
     * @default 3
     */
    rentalPeriodMax: NonNegativeInteger

    /**
     * @default '{"no": "År", "en": "Years"}'
     */
    rentalPeriodUnitLabel: Localized<string>

    /**
     * The label for the checkbox where the user confirms to be signing up.
     */
    consentCheckboxLabel: Localized<string>

    /**
     * The text on the signup button.
     *
     * @default '{"no": "Sett deg på ventelisten", "en": "Sign up for the waiting list"}'
     */
    signupButtonText: Localized<string>

    /**
     * Small text to be displayed below the signup button.
     */
    signupButtonSubText: Localized<Markdown>

    /**
     * Message to show to the user when the signup has succeeded.
     */
    successMessage: Localized<Markdown>
}) {
    const localize = useLocalize()
    const modalRef = useRef<HTMLDivElement>(null)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [submitSuccess, setSubmitSuccess] = useState(false)

    const openModal = useCallback(() => {
        setModalIsOpen(true)
    }, [setModalIsOpen])

    const closeModal = useCallback(() => {
        setModalIsOpen(false)
    }, [setModalIsOpen])

    useEffect(() => {
        const ref = modalRef.current
        ref?.addEventListener("open", openModal)

        return () => {
            ref?.removeEventListener("open", openModal)
        }
    }, [openModal])

    const handleSubmit = useCallback(
        async (formData: {
            email: Email
            phoneModel: string
            rentalPeriod: string
            newsletterConsent: boolean
        }) => {
            await postWaitingListSignup({
                email: formData.email,
                phoneModel: formData.phoneModel,
                rentalPeriod: parseInt(formData.rentalPeriod),
                newsletterConsent: formData.newsletterConsent,
            })
            setSubmitSuccess(true)
        },
        []
    )

    return (
        <div id={waitingListSignupModalId} ref={modalRef}>
            <WaitingListSignupModalComponent
                isOpen={modalIsOpen}
                onClose={closeModal}
                title={localize(section.title)}
                text={section.text ? localize(section.text) : undefined}
                form={{
                    email: { label: localize(section.emailInputLabel) },
                    phoneModel: {
                        label: localize(section.phoneModelLabel),
                        options: section.phoneModelOptions.map((pmo) => localize(pmo)),
                    },
                    rentalPeriod: {
                        label: localize(section.rentalPeriodLabel),
                        options: [...Array(section.rentalPeriodMax)].map((_, i) => ({
                            value: `${i + 1}`,
                            text: `${i + 1} ${localize(section.rentalPeriodUnitLabel)}`,
                        })),
                    },
                    newsletterConsent: { label: localize(section.consentCheckboxLabel) },
                }}
                submitButtonText={localize(section.signupButtonText)}
                submitButtonSubText={localize(section.signupButtonSubText)}
                onSubmit={handleSubmit}
                submitSuccess={submitSuccess}
                successMessage={localize(section.successMessage)}
            />
        </div>
    )
}

export function openWaitingListSignupModal(e?: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) {
    if (typeof window !== "undefined") {
        const el = window.document.getElementById(waitingListSignupModalId)
        const openModalEvent = new Event("open")
        el?.dispatchEvent(openModalEvent)
    }
    e?.preventDefault()
}

Section(WaitingListSignupModal)
