import { css } from "@emotion/react"
import { File, FileToUrl, Image } from "../../../../../../reactor"
import { Flex } from "../base/Flex"
import { Box } from "../base/Box"
import { Component } from "../../../../../../packages/editing/Component"
import { responsiveCss } from "../../helpers/css"
import { Heading } from "../typography/Heading"
import { Button } from "../buttons/Button"
import { server } from "../../../../../../server"
import { openWaitingListSignupModal } from "../../../sections/WaitingListSignupModal"

export function SeeAllCard(props: {
    heading: string
    cta: { text: string; url: string }
    image: Image
}) {
    return (
        <Flex
            direction="column"
            justifyContent="space-between"
            borderRadius="md"
            backgroundColor="_productsSeeAllBg"
            style={{ position: "relative", height: "100%", overflow: "clip" }}
            onClick={openWaitingListSignupModal}
            {...(props.cta
                ? {
                      as: "a",
                      //   href: props.cta.url,
                      "aria-label": props.cta.text,
                  }
                : {})}>
            <Box
                css={css({ padding: 32 }, responsiveCss("min", "md", { padding: 64 }))}
                style={{ zIndex: 1 }}>
                <Heading level={2} margin={{ bottom: 16 }}>
                    {props.heading}
                </Heading>
                <Button variant="dark">{props.cta.text}</Button>
            </Box>
            <img
                src={FileToUrl(props.image as any as File)}
                style={{ position: "absolute", bottom: 0, zIndex: 0 }}
                css={css(
                    { width: "100%" },
                    responsiveCss("max", "xs", {
                        width: "150%",
                        marginLeft: "-100%",
                        transform: "translateX(50%) translateY(30%);",
                    })
                )}
            />
        </Flex>
    )
}

Component(SeeAllCard, {
    name: "SeeAllCard",
    gallery: {
        path: "Cards/SeeAllCard",
        items: [
            {
                variants: [
                    {
                        props: {
                            heading: "Se hele vårt utvalg",
                            cta: { text: "Vis alt (14)", url: "" },
                            image: `${server()}/static/redoit/see-all-card.png` as any as Image,
                        },
                    },
                ],
            },
        ],
    },
})
