import { useCallback, MouseEvent, SVGProps } from "react"
import { css } from "@emotion/react"
import { Component } from "../../../../../../packages/editing/Component"
import { Box } from "../base/Box"
import { Body } from "../typography/Body"
import { Flex } from "../base/Flex"

const boxStyle = css({
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: 6,
    height: 24,
    width: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
})

export function Checkbox(props: {
    /**
     * Whether the checkbox is checked or not.
     */
    checked?: boolean

    /**
     * A label to show next to the checkbox.
     */
    label?: string

    /**
     * Name for the checkbox, relevant if part of a form.
     */
    name?: string

    /**
     * Whether this element is available for interaction or not.
     */
    disabled?: boolean

    /**
     * @reflection any
     */
    onClick?: (e: MouseEvent) => void
}) {
    const handleClick = useCallback(
        (e: MouseEvent) => {
            if (typeof props.onClick === "function") props.onClick(e)
        },
        [props.onClick]
    )

    return (
        <Flex
            onClick={handleClick}
            alignItems="center"
            style={{ cursor: props.disabled ? "default" : "pointer" }}>
            <Box
                backgroundColor={
                    props.disabled
                        ? props.checked
                            ? "gray200"
                            : "gray100"
                        : props.checked
                          ? "brand"
                          : "grayWhite"
                }
                borderColor={props.disabled ? "gray200" : props.checked ? "brand" : "gray200"}
                css={css(boxStyle)}>
                <input
                    type="checkbox"
                    disabled={props.disabled}
                    style={{ opacity: 0, position: "absolute", cursor: "inherit" }}
                />
                {props.checked && <Checkmark />}
            </Box>
            {props.label && (
                <Body size="md" margin={{ left: 16 }}>
                    {props.label}
                </Body>
            )}
        </Flex>
    )
}

Component(Checkbox, {
    name: "Checkbox",
    gallery: {
        path: "Controllers/Checkbox",
        items: [
            {
                title: "Enabled",
                variants: [
                    {
                        label: "Interactive",
                        props: (state = { checked: false }, setState) => ({
                            checked: state.checked,
                            onClick: () => {
                                setState({ checked: !state.checked })
                            },
                        }),
                    },
                    { label: "Checked", props: { checked: true } },
                ],
            },
            {
                title: "Disabled",
                variants: [
                    { label: "Unchecked", props: { checked: false, disabled: true } },
                    { label: "Checked", props: { checked: true, disabled: true } },
                ],
            },
            {
                title: "With label",
                variants: [
                    {
                        props: (state = { checked: false }, setState) => ({
                            checked: state.checked,
                            label: "Jeg vil gjerne vite mer om bærekraft og Redoits tilbud.",
                            onClick: () => {
                                setState({ checked: !state.checked })
                            },
                        }),
                    },
                ],
            },
        ],
    },
})

const Checkmark = (props: React.SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={12} fill="none" {...props}>
        <path
            fill="#fff"
            d="M5.457 8.293 2.164 5 .75 6.414l4.707 4.707 9.707-9.707L13.75 0 5.457 8.293Z"
            style={{
                fill: "#fff",
                fillOpacity: 1,
            }}
        />
    </svg>
)
