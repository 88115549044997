import { ComponentProps, Fragment, ReactNode } from "react"
import { Route, Routes } from "react-router-dom"
import { Global, SerializedStyles } from "@emotion/react"
import { LocalesContext } from "../../localization/client-side/useLocalesContext"
import { ModalProvider } from "../../modal/Modal"
import { Gallery } from "../../../gallery/Gallery"
import { Studio } from "../../../studio/Studio"
import { useDefaultLocale, useLocales, useWebPermissions } from "../../../studio/client"
import { useRedirectTrailingSlash } from "../../ssr/useRedirectTrialingSlash"
import { EditableSiteLazy } from "../../editing/EditableSiteLazy"
import { CookieConsentView } from "../../cookie-consent/CookieConsentView"
import { Login } from "../../../studio/Login"
import { WebPage } from "./WebPage"
import { EditableSections } from "../../editing/EditableSections"

/**
 * TODO: Probably want studio and gallery by default, so maybe flip to `noGallery` and `noStudio`
 * or something.
 */
export function WebSite(config: {
    /**
     * Set to true if WebSite should have a component gallery.
     */
    gallery?: boolean

    /**
     * Set to true if WebSite should have studio.
     */
    studio?: boolean

    /**
     * A function returning a rendered Login component to use custom login page.
     */
    renderLogin?: () => ReactNode

    /**
     * Global styles that will be applied to the entire site including gallery and studio.
     */
    globalStyles?: SerializedStyles

    /**
     * Props to forward to each EditableSection.
     */
    EditableSectionsProps?: Partial<Omit<ComponentProps<typeof EditableSections>, "sections">>

    /**
     * Include routes or other children
     */
    children?: ReactNode
}) {
    useRedirectTrailingSlash()
    const { data: locales } = useLocales()
    const { data: permissions } = useWebPermissions()
    const defaultLocale = useDefaultLocale().data?.defaultLocale

    if (!locales || !permissions || !defaultLocale) return null

    return (
        <>
            <ModalProvider>
                {config.globalStyles ? <Global styles={config.globalStyles} /> : null}
                <LocalesContext.Provider value={locales}>
                    <Routes>
                        {config.studio && Studio()}
                        {config.gallery && Gallery()}
                        <Route
                            path="/login"
                            element={config.renderLogin ? config.renderLogin() : <Login />}
                        />
                        <Route
                            element={
                                <>
                                    <EditableSiteLazy condition={!!permissions?.canEditWebsite} />
                                    <CookieConsentView />
                                </>
                            }>
                            {locales.map((l) =>
                                l.key === defaultLocale ? (
                                    <Fragment key={l.key.toString()}>
                                        <Route
                                            index
                                            element={
                                                <WebPage
                                                    EditableSectionsProps={
                                                        config.EditableSectionsProps
                                                    }
                                                    locale={l.key}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/:slug"
                                            element={
                                                <WebPage
                                                    locale={l.key}
                                                    EditableSectionsProps={
                                                        config.EditableSectionsProps
                                                    }
                                                />
                                            }
                                        />
                                    </Fragment>
                                ) : (
                                    <Fragment key={l.key.toString()}>
                                        <Route
                                            index
                                            path={`/${l.key.toString()}`}
                                            element={
                                                <WebPage
                                                    EditableSectionsProps={
                                                        config.EditableSectionsProps
                                                    }
                                                    locale={l.key}
                                                />
                                            }
                                        />
                                        <Route
                                            path={`/${l.key.toString()}/:slug`}
                                            element={
                                                <WebPage
                                                    EditableSectionsProps={
                                                        config.EditableSectionsProps
                                                    }
                                                    locale={l.key}
                                                />
                                            }
                                        />
                                    </Fragment>
                                )
                            )}
                            {config.children}
                        </Route>
                    </Routes>
                </LocalesContext.Provider>
            </ModalProvider>
        </>
    )
}
