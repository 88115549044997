import { useCallback, useMemo, useRef, useState } from "react"
import { Component } from "../../../../../../packages/editing/Component"
import { useLocalize } from "../../../../../../packages/localization/client-side/useLocalize"
import { MarkdownView } from "../../../../../../packages/markdown-edit/MarkdownView"
import { Button } from "../buttons/Button"
import { Checkbox } from "../controllers/Checkbox"
import { TextInput } from "../controllers/TextInput"
import { Body } from "../typography/Body"
import { Modal } from "./Modal"
import { Email, Markdown } from "../../../../../../reactor"
import { Text } from "../typography/Text"
import { Select } from "../inputs/Select"

export function WaitingListSignupModal(props: {
    title: string
    text?: Markdown
    submitButtonText: string
    submitButtonSubText?: Markdown
    isOpen: boolean
    form: {
        email: { label: string }
        phoneModel: { label: string; options: string[] }
        rentalPeriod: { label: string; options: { value: string; text: string }[] }
        newsletterConsent: { label: string }
    }
    onSubmit: (formData: {
        email: Email
        phoneModel: string
        rentalPeriod: string
        newsletterConsent: boolean
    }) => void
    onClose?: () => void
    submitSuccess?: boolean
    successMessage: Markdown
}) {
    const emailInputRef = useRef<HTMLInputElement>(null)
    const localize = useLocalize()
    const [formValues, setFormValues] = useState(initialFormValue)

    const setFormValue = useCallback(
        (key: string, value: string | boolean) => {
            setFormValues({ ...formValues, [key]: value })
        },
        [formValues, setFormValues]
    )

    const [emailIsValid, setEmailIsValid] = useState<boolean | undefined>()

    const validatedFormValues = useMemo(() => {
        try {
            return {
                ...formValues,
                email: Email(formValues.email),
            }
        } catch (err) {
            return undefined
        }
    }, [formValues])

    const validateEmail = useCallback(() => {
        try {
            Email(formValues.email)
            setEmailIsValid(true)
            return true
        } catch (err) {
            setEmailIsValid(false)
            return false
        }
    }, [formValues.email])

    const handleSubmitButtonClick = useCallback(() => {
        if (validatedFormValues) {
            props.onSubmit(validatedFormValues)
        } else {
            emailInputRef.current?.focus()
        }
    }, [props, validatedFormValues])

    const handleClose = useCallback(() => {
        setFormValues(initialFormValue)
        setEmailIsValid(undefined)
        props.onClose?.()
    }, [props])

    return (
        <Modal
            isOpen={props.isOpen}
            header={{ title: props.title, closeButton: true }}
            onClose={handleClose}>
            {props.submitSuccess ? (
                <div style={{ display: "grid", gridAutoFlow: "row", gap: 24 }}>
                    <MarkdownView value={props.successMessage} />
                    <Button variant="secondary" onClick={props.onClose}>
                        {localize({ no: "Lukk", en: "Close" })}
                    </Button>
                </div>
            ) : (
                <div style={{ display: "grid", gridAutoFlow: "row", gap: 24 }}>
                    <Body size="xl">{props.text}</Body>
                    <TextInput
                        ref={emailInputRef}
                        label={props.form.email.label}
                        value={formValues.email}
                        onChange={(e) => {
                            if (emailIsValid === false) validateEmail()
                            setFormValue("email", e.target.value)
                        }}
                        onBlur={validateEmail}
                        warning={emailIsValid === false ? localize(invalidEmailMessage) : undefined}
                    />
                    <Select
                        label={props.form.phoneModel.label}
                        options={[
                            { value: "", text: "" },
                            ...props.form.phoneModel.options.map((o) => ({ value: o, text: o })),
                        ]}
                        value={formValues.phoneModel}
                        onChange={(e) => setFormValue("phoneModel", e.target.value)}
                    />
                    <Select
                        label={props.form.rentalPeriod.label}
                        options={[{ value: "", text: "" }, ...props.form.rentalPeriod.options]}
                        value={formValues.rentalPeriod}
                        onChange={(e) => setFormValue("rentalPeriod", e.target.value)}
                    />
                    <Checkbox
                        checked={formValues.newsletterConsent}
                        onClick={() =>
                            setFormValue("newsletterConsent", !formValues.newsletterConsent)
                        }
                        label={props.form.newsletterConsent.label}
                    />
                    <div style={{ display: "grid", gridAutoFlow: "row", gap: 8 }}>
                        <Button onClick={handleSubmitButtonClick}>{props.submitButtonText}</Button>
                        {props.submitButtonSubText ? (
                            <Text
                                variant="body"
                                size="sm"
                                color="gray300"
                                style={{ textAlign: "center" }}>
                                <MarkdownView
                                    value={props.submitButtonSubText}
                                    renderers={{
                                        paragraph: (p) => <div>{p.children}</div>,
                                    }}
                                />
                            </Text>
                        ) : null}
                    </div>
                </div>
            )}
        </Modal>
    )
}

const initialFormValue = {
    email: "",
    phoneModel: "",
    rentalPeriod: "",
    newsletterConsent: false,
}

const invalidEmailMessage = { no: "Ugyldig e-postadresse.", en: "Invalid email address." }

Component(WaitingListSignupModal, {
    name: "WaitingListSignupModal",
    gallery: {
        path: "Modal/WaitingListSignupModal",
        items: [
            {
                variants: [
                    {
                        props: {
                            title: "Stay up to date",
                            text: Markdown(
                                "We're super excited to let you in on a little secret – something amazing is coming your way soon! Get ready to be wowed by the future of Redoing it. Stay in the loop by signing up for exclusive updates and be the first to know when we go live. Thanks for sticking with us – we promise it'll be worth the wait!"
                            ),
                            submitButtonText: "Sign up",
                            isOpen: true,
                            onSubmit: (consent) => {
                                // eslint-disable-next-line no-console
                                console.log("Submitted", consent)
                            },
                            form: {
                                email: { label: "E-postadresse" },
                                phoneModel: {
                                    label: "Velg telefonmodell",
                                    options: ["iPhone 12", "iPhone 13"],
                                },
                                rentalPeriod: {
                                    label: "Velg leieperiode",
                                    options: [
                                        { value: "1", text: "1 år" },
                                        { value: "2", text: "2 år" },
                                    ],
                                },
                                newsletterConsent: {
                                    label: "Jeg ønsker å motta nyhetsbrev fra Redoit",
                                },
                            },
                            successMessage: Markdown("Success!"),
                        },
                    },
                ],
            },
        ],
    },
})
