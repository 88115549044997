import { CSSProperties } from "react"
import { css, jsx } from "@emotion/react"
import { Box, BoxProps } from "./Box"

type FlexProps<T extends keyof JSX.IntrinsicElements = "div"> = BoxProps<T> & {
    direction?: CSSProperties["flexDirection"]
    flex?: CSSProperties["flex"]
    basis?: CSSProperties["flexBasis"]
    grow?: CSSProperties["flexGrow"]
    shrink?: CSSProperties["flexShrink"]
    alignItems?: CSSProperties["alignItems"]
    justifyContent?: CSSProperties["justifyContent"]
    alignSelf?: CSSProperties["alignSelf"]
}

export function Flex<T extends keyof JSX.IntrinsicElements = "div">({
    direction,
    flex,
    basis,
    grow,
    shrink,
    alignItems,
    justifyContent,
    alignSelf,
    children,
    ...rest
}: FlexProps<T>) {
    const boxProps = rest as BoxProps

    return jsx(Box, {
        ...boxProps,
        css: css({
            display: "flex",
            flex,
            flexBasis: basis,
            flexGrow: grow,
            flexShrink: shrink,
            flexDirection: direction,
            alignItems,
            justifyContent,
            alignSelf,
        }),
        children,
    })
}
